import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const redirectUrl = next.data['url']; // Retrieve the URL from route data
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return false; // Stop Angular routing
    }
    return true; // No URL provided, do not redirect
  }
}

const routes: Routes = [
  { path: 'home', redirectTo: ''},
  { path: 'profile/view', redirectTo: 'profile'},
  { path: '', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule) },
  { path: 'videorecord', loadChildren: () => import('./pages/recordvideo/recordvideo.module').then(m => m.RecordVideoPageModule) },
  { path: 'financing', loadChildren: () => import('./pages/financing/financing.module').then(m => m.FinancingPageModule) },
  { path: 'scholarships', loadChildren: () => import('./pages/financing/scholarships/scholarships.module').then(m => m.ScholarshipsPageModule) },
  { path: 'careerservices', loadChildren: () => import('./pages/careerservices/careerservices.module').then( m => m.CareerservicesPageModule)},
  { path: 'discord', loadChildren: () => import('./pages/discordconnect/discordconnect.module').then( m => m.DiscordconnectPageModule)},
  { path: 'discord/astro', loadChildren: () => import('./pages/discordconnect/discordconnect.module').then( m => m.DiscordconnectPageModule)},
  { path: 'discord/add/:channel', loadChildren: () => import('./pages/discordconnect/discordconnect.module').then( m => m.DiscordconnectPageModule)},
  { path: 'discord/remove/:channel', loadChildren: () => import('./pages/discordconnect/discordconnect.module').then( m => m.DiscordconnectPageModule)},
  { path: 'newcareers', loadChildren: () => import('./pages/newcareers/newcareers.module').then( m => m.NewcareersPageModule) },
  { path: 'scholarships/reskillamerica', loadChildren: () => import('./pages/financing/scholarships/reskillamerica/reskillamerica.module').then(m => m.ReskillamericaPageModule) },
  { path: 'scholarships/highschoolgraduates', loadChildren: () => import('./pages/financing/scholarships/highschoolgraduates/highschoolgraduates.module').then(m => m.HighschoolgraduatesPageModule) },
  { path: 'scholarships/warriorrising', loadChildren: () => import('./pages/financing/scholarships/warriorrising/warriorrising.module').then(m => m.WarriorrisingPageModule) },
  { path: 'scholarships/nuyou', loadChildren: () => import('./pages/financing/scholarships/nuyou/nuyou.module').then(m => m.NuyouPageModule) },
  { path: 'scholarships/bestbuybounceback', loadChildren: () => import('./pages/financing/scholarships/bestbuybounceback/bestbuybounceback.module').then(m => m.BestbuybouncebackPageModule) },
  { path: 'scholarships/nativesintech', loadChildren: () => import('./pages/financing/scholarships/nativesintech/nativesintech.module').then(m => m.NativesintechPageModule) },
  { path: 'scholarships/frameyourfuture', loadChildren: () => import('./pages/financing/scholarships/frameyourfuture/frameyourfuture.module').then(m => m.FrameyourfuturePageModule) },
  { path: 'scholarships/waretraining', canActivate: [RedirectGuard], data: { url: 'https://www.nucamp.co/scholarships/waretraining' }, component: RedirectGuard },
  { path: 'scholarships/miachievement', canActivate: [RedirectGuard], data: { url: 'https://www.nucamp.co/scholarships/miachievement' }, component: RedirectGuard },
  { path: 'scholarships/googlewomenintech', loadChildren: () => import('./pages/financing/scholarships/googlewomenintech/googlewomenintech.module').then(m => m.GoogleWomenInTechPageModule) },
  { path: 'frequentlyaskedquestions', loadChildren: () => import('./pages/frequentlyaskedquestions/frequentlyaskedquestions.module').then(m => m.FrequentlyaskedquestionsPageModule) },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)},
  { path: 'meetus', loadChildren: () => import('./pages/meetus/meetus.module').then(m => m.MeetusPageModule) },
  { path: 'ambassador/apply', loadChildren: () => import('./pages/ambassador/ambassador-apply/ambassadorapply.module').then(m => m.AmbassadorapplyPageModule) },
  { path: 'ambassador', redirectTo: 'ambassador/dashboard', pathMatch:'full'},
  { path: 'ambassador/:action', loadChildren: () => import('./pages/ambassador/ambassador-dashboard/ambassador.module').then(m => m.AmbassadorPageModule) },
  { path: 'instructor', redirectTo: 'instructor/dashboard', pathMatch:'full'},
  { path: 'instructor/:action', loadChildren: () => import('./pages/instructor/instructor.module').then(m => m.InstructorPageModule) },
  { path: 'community/:state/:name', loadChildren: () => import('./pages/community/community.module').then(m => m.CommunityPageModule) },
  { path: 'browsecommunities', loadChildren: () => import('./pages/browse-communities/browse-communities.module').then(m => m.BrowseCommunitiesPageModule) },
  { path: 'requestgrading', loadChildren: () => import('./pages/requestgrading/requestgrading.module').then(m => m.RequestgradingPageModule) },
  { path: 'communitymanager/:state/:name', loadChildren: () => import('./pages/communitymanager/communitymanager.module').then(m => m.CommunitymanagerPageModule) },
  { path: 'bootcamp-overview/:friendlyid', loadChildren: () => import('./pages/bootcamp-overview/bootcamp-overview.module').then(m => m.BootcampoverviewPageModule) },
  { path: 'complete-bootcamp/:friendlyid', loadChildren: () => import('./pages/complete-bootcamp/complete-bootcamp.module').then(m => m.CompletebootcampPageModule) },
  { path: 'complete-bootcamp/:friendlyid/:id', loadChildren: () => import('./pages/complete-bootcamp-register/complete-bootcamp-register.module').then(m => m.CompletebootcampregisterPageModule) },
  { path: 'bootcampdetails/:id', loadChildren: () => import('./pages/bootcampdetails/bootcampdetails.module').then(m => m.BootcampdetailsPageModule) },
  { path: 'bootcampfollow/:bdid', loadChildren: () => import('./pages/bootcampfollow/bootcampfollow.module').then(m => m.BootcampfollowPageModule) },
  { path: 'contest/jobhuntinggems', loadChildren: () => import('./pages/contests/jobhuntinggems/jobhuntinggems.module').then(m => m.JobhuntinggemsPageModule) },
  { path: 'business/jobboard', loadChildren: () => import('./pages/business/jobboard-post/jobboard-post.module').then(m => m.JobBoardPostPageModule) },
  { path: 'business/viewjobs', loadChildren: () => import('./pages/business/jobboard-view/jobboard-view.module').then(m => m.JobBoardViewPageModule) },
  { path: 'business/invoice/:invoiceid', loadChildren: () => import('./pages/business/business.module').then(m => m.BusinessPageModule) },
  { path: 'business/invoice', loadChildren: () => import('./pages/business/business.module').then(m => m.BusinessPageModule) },
  { path: 'business', loadChildren: () => import('./pages/business/business.module').then(m => m.BusinessPageModule) },
  // { path: 'bloglist', redirectTo: 'bloglist/' + new Date().getFullYear(), pathMatch:'full' },
  // { path: 'bloglist/:year', loadChildren: () => import('./pages/blog/bloglist/bloglist.module').then(m => m.BloglistPageModule) },
  { path: 'blogs', loadChildren: () => import('./pages/blog/bloglist/bloglist.module').then(m => m.BloglistPageModule) },
  { path: 'blogs/authors/:author', loadChildren: () => import('./pages/blog/blogauthors/blogauthors.module').then(m => m.BlogauthorsPageModule) },
  { path: 'blog/:shortid', loadChildren: () => import('./pages/blog/blog/blog.module').then(m => m.BlogPageModule) },
  { path: 'blogedit/:mode', loadChildren: () => import('./pages/blog/blogedit/blogedit.module').then(m => m.BlogeditPageModule) },
  { path: 'blogedit/:shortid/:mode', loadChildren: () => import('./pages/blog/blogedit/blogedit.module').then(m => m.BlogeditPageModule) },
  { path: 'referral', loadChildren: () => import('./pages/affiliate/referral/referral.module').then(m => m.ReferralPageModule) },
  { path: 'affiliate', loadChildren: () => import('./pages/affiliate/referral/referral.module').then(m => m.ReferralPageModule) },
  { path: 'affiliate/terms', loadChildren: () => import('./pages/affiliate/programterms/programterms.module').then(m => m.ProgramtermsPageModule) },
  { path: 'number1', loadChildren: () => import('./pages/number1/number1.module').then(m => m.Number1PageModule) },
  { path: 'aboutus', loadChildren: () => import('./pages/aboutus/aboutus.module').then(m => m.AboutusPageModule) },
  { path: 'free-workshops', loadChildren: () => import('./pages/free-workshops/free-workshops.module').then(m => m.FreeworkshopsPageModule) },
  { path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule) },
  { path: 'review', loadChildren: () => import('./pages/review/review.module').then(m => m.ReviewPageModule) },
  { path: 'termsofuse', loadChildren: () => import('./pages/termsofuse/termsofuse.module').then(m => m.TermsofusePageModule) },
  { path: 'smstermsofuse', loadChildren: () => import('./pages/smstermsofuse/smstermsofuse.module').then(m => m.SmstermsofusePageModule) },
  { path: 'regulatory', loadChildren: () => import('./pages/regulatory/regulatory.module').then(m => m.RegulatoryPageModule) },
  { path: 'codeofconduct', loadChildren: () => import('./pages/codeofconduct/codeofconduct.module').then(m => m.CodeofconductPageModule) },
  { path: 'cancelappointment/:useruid/:openhouseid', loadChildren: () => import('./pages/cancelappointment/cancelappointment.module').then(m => m.CancelappointmentPageModule) },
  { path: 'graduates/survey/:useruid', loadChildren: () => import('./pages/graduates/survey/survey.module').then(m => m.GraduatessurveyPageModule) },
  { path: 'graduates/projects', loadChildren: () => import('./pages/studentproject/studentproject.module').then(m => m.StudentprojectPageModule) },
  { path: 'cancelappointment/uid/openhouseid', loadChildren: () => import('./pages/cancelappointment/cancelappointment.module').then(m => m.CancelappointmentPageModule) },
  { path: 'unsubscribe/:userid/:bootcampid', loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then(m => m.UnsubscribePageModule) },
  // { path: 'admin/cohorts', loadChildren: () => import('./pages/admin/cohorts/cohorts.module').then( m => m.AdmincohortsPageModule)},
  { path: 'admin/rollovers', loadChildren: () => import('./pages/admin/rollovers/rollovers.module').then( m => m.AdminrolloversPageModule)},
  { path: 'admin/scheduling', loadChildren: () => import('./pages/admin/scheduling/scheduling.module').then( m => m.SchedulingPageModule)},
  { path: 'admin/performance', loadChildren: () => import('./pages/admin/performance/performance.module').then( m => m.PerformancePageModule)},
  { path: 'admin/instructorcoaching', loadChildren: () => import('./pages/admin/instructor-coaching/instructor-coaching.module').then( m => m.InstructorCoachingPageModule)},
  { path: 'admin/grading', loadChildren: () => import('./pages/instructor-dashboard/instructor-grading/instructor-grading.module').then( m => m.InstructorgradingPageModule)},
  { path: 'admin/customernotes', loadChildren: () => import('./pages/admin/studentprospect-notes/studentprospect-notes.module').then( m => m.StudentProspectNotesPageModule)},
  { path: 'admin/customernotes/:userid', loadChildren: () => import('./pages/admin/studentprospect-notes/studentprospect-notes.module').then( m => m.StudentProspectNotesPageModule)},
  { path: 'admin/monitoring', loadChildren: () => import('./pages/admin/monitoring/monitoring.module').then( m => m.MonitoringPageModule)},
  { path: 'admin/bbbmeetings', loadChildren: () => import('./pages/admin/bbbmeetings/bbbmeetings.module').then( m => m.AdminbbbmeetingsPageModule)},
  { path: 'admin/feedback', loadChildren: () => import('./pages/admin/feedback/feedback.module').then( m => m.FeedbackPageModule)},
  { path: 'admin/blogs', loadChildren: () => import('./pages/admin/blogmanagement/blogmanagement.module').then( m => m.AdminblogmanagementPageModule)},
  { path: 'admin/videorecordings', loadChildren: () => import('./pages/admin/videorecordings/videorecordings.module').then( m => m.AdminvideorecordingsPageModule)},
  { path: 'admin/scholarships', loadChildren: () => import('./pages/admin/scholarships/scholarships.module').then( m => m.AdminscholarshipsPageModule)},
  { path: 'admin/refunds', loadChildren: () => import('./pages/admin/refunds/refunds.module').then( m => m.AdminrefundsPageModule)},
  { path: 'admin/communities', loadChildren: () => import('./pages/admin/communities/communities.module').then( m => m.AdmincommunitiesPageModule)},
  { path: 'admin/workshops', loadChildren: () => import('./pages/admin/workshops/workshops.module').then( m => m.AdminworkshopsPageModule)},
  { path: 'admin/aiproducer', loadChildren: () => import('./pages/admin/aiproducer/aiproducer-alpha.module').then( m => m.AIProduceralphaPageModule)},
  { path: 'admin/ambassador/list', loadChildren: () => import('./pages/admin/ambassadors/list/ambassador-list.module').then( m => m.AdminambassadorlistPageModule)},
  { path: 'admin/ambassador/prospects', loadChildren: () => import('./pages/admin/ambassadors/prospects/ambassador-prospects.module').then( m => m.AdminambassadorprospectsPageModule)},
  { path: 'admin/ambassador/transactions', loadChildren: () => import('./pages/admin/ambassadors/transactions/ambassador-transactions.module').then( m => m.AdminambassadortransactionsPageModule)},
  { path: 'admin/ambassador/payments', loadChildren: () => import('./pages/admin/ambassadors/payments/ambassador-payments.module').then( m => m.AdminambassadorpaymentsPageModule)},
  { path: 'admin/ambassador/events', loadChildren: () => import('./pages/admin/ambassadors/events/ambassador-events.module').then( m => m.AdminambassadoreventsPageModule)},
  { path: 'admin/ambassador/calls', loadChildren: () => import('./pages/admin/ambassadors/calls/ambassador-calls.module').then( m => m.AdminambassadorcallsPageModule)},
  { path: 'admin/business/invoices', loadChildren: () => import('./pages/admin/business/invoices/invoices.module').then( m => m.AdmininvoicesPageModule)},
  { path: 'admin/business/companies', loadChildren: () => import('./pages/admin/business/companies/companies.module').then( m => m.AdmincompaniesPageModule)},
  { path: 'admin/instructors', loadChildren: () => import('./pages/admin/instructors/instructors.module').then( m => m.AdmininstructorsPageModule)},
  { path: 'admin/students', loadChildren: () => import('./pages/admin/students/students.module').then( m => m.AdminstudentsPageModule)},
  { path: 'admin/referrals', loadChildren: () => import('./pages/admin/referrals/referrals.module').then( m => m.AdminreferralsPageModule)},
  { path: 'admin/careercoaching', loadChildren: () => import('./pages/admin/careercoaching/careercoaching.module').then( m => m.AdmincareercoachingPageModule)},
  { path: 'admin/graduations', loadChildren: () => import('./pages/admin/graduations/graduations.module').then( m => m.AdmingraduationsPageModule)},
  { path: 'admin/coupons', loadChildren: () => import('./pages/admin/coupons/couponview/couponview.module').then( m => m.AdmincouponViewPageModule)},
  { path: 'admin/studentloans', loadChildren: () => import('./pages/admin/studentloans/studentloansview/studentloansview.module').then( m => m.AdminstudentloansviewPageModule)},
  { path: 'admin/instructorpayments', loadChildren: () => import('./pages/admin/payments/payments.module').then( m => m.AdminpaymentsPageModule)},
  { path: 'admin/advisormeetings', loadChildren: () => import('./pages/admin/advisor/advisor.module').then( m => m.AdvisorPageModule)},
  { path: 'admin/decrypt', loadChildren: () => import('./pages/admin/decrypt/decrypt.module').then( m => m.AdmindecryptPageModule)},
  { path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledNonBlocking' , anchorScrolling: 'enabled'}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
